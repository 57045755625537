<template>

      <v-card class="pa-8 mt-4" outlined color="grey lighten-4" >
        
        <div class="grey--text font-weight-bold  text--darken-2" >
          <div v-if="dataset.postprocessStatus === 'POSTPROCESSED'">
            <v-icon color="primary" class="pr-3" large>mdi-check-decagram</v-icon> 
            The database has been analyzed successfully and is ready to be explored.
              <v-btn class="primary px-12 ml-8"  @click="onSave()" >
                RE-ANALYZE
              </v-btn>            
          </div>
          <div v-if="dataset.postprocessStatus === 'POSTPROCESSING' && dataset.error === null" >
                <v-progress-circular :width="3" class="px-3"  color="orange" indeterminate ></v-progress-circular>
                <span class="ml-3">
            The database is being analyzed. It may take some minutes...
                </span>
          </div>
          <div v-if="dataset.postprocessStatus === 'POSTPROCESSING' && dataset.error !== null" class="d-flex flex-row">
            <div>
              <v-icon color="red" class="pr-3" large>mdi-alert-circle</v-icon> 
              Database analysis failed! Try agian or contact support.
            </div>
            <div >
              <v-btn class="primary px-12 ml-8"  @click="onSave()" >
                RETRY ANALYSIS
              </v-btn>
            </div>
          </div>
          <div v-if="dataset.postprocessStatus === null && isColumnGroupsAdded == false" >
              <v-icon color="orange" class="pr-3" large>mdi-alert-octagram</v-icon> 
              Before analyzing the database, it is required define Composition AT% and Composition WT% columns.
          </div>          
          <div v-if="dataset.postprocessStatus === null && isColumnGroupsAdded == true" class="d-flex flex-row">
            <div>
              <v-icon color="red" class="pr-3" large>mdi-alert-octagram</v-icon> 
              The database has not been analyzed! It is required to perform an analysis operation so as to be able to be explored.
            </div>
            <div >
              <v-btn class="primary px-12 ml-8"  @click="onSave()" >
                ANALYZE
              </v-btn>
            </div>
          </div>          
        </div>
      </v-card>
</template>
<script>
import {getDataset, postprocessDataset, getDatasetFields} from '@/api/dataset'

  export default {
    name: 'AnalyzeDataset',
    data () {
      return {
        getDatasetTimer: null,

        datasetId: this.$route.params.id,
        isColumnGroupsAdded: null,
        dataset:{
          name:'',
          datasetType: 1,
          createdAt:'',
          fileName: ' ',
          status:{
            name:''
          }
        }
      }
    },
    methods: {
      onSave(){
        postprocessDataset(this.$route.params.id, 'Analysis operation has started successfully')
        this.getDatasetTimer = setInterval(() => this.getDatasetInfo(), 2000);

      },
      getDatasetInfo(){
        const _this = this
        console.log('heer')
        getDataset(this.datasetId, function(ds){
          _this.dataset = ds
          if(_this.getDatasetTimer === null) {
            if(_this.dataset.postprocessStatus === 'POSTPROCESSING' && _this.dataset.error === null)
            {
              _this.getDatasetTimer = setInterval(() => _this.getDatasetInfo(), 2000);
            }
          } else {
            if(_this.dataset.postprocessStatus === 'POSTPROCESSED' || _this.dataset.error !== null)
            {
              clearInterval(_this.getDatasetTimer); 
            }
          }

        })
      }
    },
    beforeRouteLeave (to, from , next) {
      if(this.getDatasetTimer !== null) {
        clearInterval(this.getDatasetTimer); 
      }
      next()
    },
    created () {
      this.getDatasetInfo()
      const _this = this
      getDatasetFields(this.datasetId, function(fields){
        let groups= new Set()
        let tmp = fields.map(item => item.groups.map(g => g.groupType))
        tmp.forEach(item => item.forEach(m => groups.add(m)))
        _this.isColumnGroupsAdded = ( groups.has('COMPOSITION_WT') && groups.has('COMPOSITION_AT') )
      })
    }
  }
</script>
