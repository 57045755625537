<template>
    <v-container >

<v-card  >
    <v-card-title>
      COLUMNS
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"  :disabled="selected.length == 0" class="mb-2" v-bind="attrs" v-on="on"  >
              <v-icon> mdi-tag-plus </v-icon>
               Assign to Group
            </v-btn>
          </template>
           <v-form ref="form" v-model="valid" lazy-validation >
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5"> Assign to Group</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                    <v-select class="mt-5 mb-5"  prepend-icon="mdi-tag" v-model="newGroup" :items="allFieldGroups" item-text="name" item-value="id" label="Group" ></v-select>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn :disabled="!valid" color="blue darken-1" text @click="addToGroup" >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>
        </v-dialog>
        <v-dialog v-model="dialogRemove" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"  :disabled="revokeGroups.length == 0" class="ml-5 mb-2" v-bind="attrs" v-on="on"  >
              <v-icon> mdi-delete </v-icon>
               Remove from Group
            </v-btn>
          </template>
           <v-form ref="form" lazy-validation >
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5"> Remove from Group</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                    <v-select class="mt-5 mb-5"  prepend-icon="mdi-tag" v-model="selectedGroupToRemove" :items="revokeGroups" item-text="name" item-value="id" label="Group" ></v-select>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialigRemove" >
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="batchRemoveFromGroup" >
                Revoke
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>
        </v-dialog>        
    </v-card-title>
  <v-data-table
    v-model="selected"
    checkbox-color="primary"
    :headers="headers"
    :items="fields"
    item-key="id"
    :search="searchedFieldName"
    show-select
    class="elevation-1"
  >
    <template v-slot:header.groups="{ }">
      <v-select :items="fieldGroups" v-model="searchedGroups"  multiple  prepend-icon="mdi-tag-multiple"  item-text="name" item-value="id" label="Group" >
      </v-select>
    </template>
    <template v-slot:header.title="{ }">
      <v-text-field
        v-model="searchedFieldName"
        append-icon="mdi-magnify"
        label="Name"
        class="mt-n5"
        single-line
        hide-details
      ></v-text-field>
    </template>
    <template v-slot:item.groups="{ item }">
      <div>
        <div v-if="item.groups.length > 0">
          <v-chip v-for="group in item.groups" :color="group.color" close :key="group.id" outlined @click:close="removeGroupFromField(item, group)">
            {{ group.name }}
          </v-chip>
        </div>
        <div v-else class="grey--text text--lighten-1">NO GROUP</div>
      </div>
    </template>
  </v-data-table>
</v-card>
    </v-container>
</template>

<script>
  import { getFieldGroups } from '@/api/settings'
  import { getDatasetFields, addDatasetFieldsToGroup, removeDatasetFieldsFromGroup } from '@/api/dataset'
  export default {
    name: 'DatasetColumns',
    data() {
      return {
        dialog: false,
        dialogRemove: false,
        newGroup: 1,
        selectedGroupToRemove:1,
        valid: false,
        searchedFieldName: '',
        searchedGroups: [],
        allFieldGroups: [],
        revokeGroups:[],
        datasetId: this.$route.params.id,
        fields: [],
        fieldGroups: [],
        selected: [],
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'title',
          },
          {
            text: 'Groups',
            align: 'start',
            sortable: false,
            value: 'groups',
            filter: value => {
              const groups = value.map(g => {return g.id})
              if (this.searchedGroups.length === 0) return true
              return this.searchedGroups.filter(value => groups.includes(value)).length > 0;
            },            
          }          
        ], colors: [
          'lime darken-2',
          'indigo darken-1',
          'green darken-2',
          'orange darken-1',
          'purple lighten-1',
          'cyan darken-1',
          'grey darken-2',
          'brown lighten-1',
          'red lighten-1',
          'teal darken-1',
          'blue-grey darken-2',
          'light-green darken-1',
        ]
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogRemove (val) {
        val || this.closeDialigRemove()
      },
      selected (val) {
        const s= new Set()
        for (let field of val){
          for (let grp of field.groups){
            s.add(grp.id)
          }
        }
        console.log(s)
        this.revokeGroups = this.allFieldGroups.filter(grp => s.has(grp.id))
        if(this.revokeGroups.length > 0){
          this.selectedGroupToRemove=this.revokeGroups[0].id
        }
      }
    },
    created() {
      this.loadFields()
    },
    methods: {
      batchRemoveFromGroup(){
        const _this = this
        const payload = {
          groupid: this.selectedGroupToRemove,
          fields: this.selected.map(item => item.id)
        }
        removeDatasetFieldsFromGroup(this.datasetId, payload, function(){
            _this.loadFields()
            _this.closeDialigRemove()
          }, 'Fields sucessfully removed from the group ')
      },
      closeDialigRemove(){
        this.dialogRemove = false
        this.$nextTick(() => {
          this.selectedGroupToRemove = 1
        })        
      },
      removeGroupFromField(item, group){
        const _this = this
        const payload = {
          groupid: group.id,
          fields: [ item.id ]
        }
        removeDatasetFieldsFromGroup(this.datasetId, payload, function(){
            _this.loadFields()
          }, '"' + item.title + '" sucessfully removed from group "'+ group.name +'"')
      },
      addToGroup(){
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          const payload = {
            groupid : this.newGroup,
            fields: this.selected.map(f => {return f.id})
          }
          const groupName = this.allFieldGroups.find( g => g.id ==  this.newGroup).name
          const _this = this
          addDatasetFieldsToGroup(this.datasetId, payload, function(){
            _this.loadFields()
            _this.close()
          }, 'Selected fields successfully added to "'+ groupName +'"')
        }
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedFieldGroup = Object.assign({}, this.defaultItem)
          //this.editedIndex = -1
        })
      },
      loadFields(){
        const _this=this
        getFieldGroups(function(res){
          _this.allFieldGroups = res
          _this.newGroup=res[0].id
        })
        getDatasetFields(this.datasetId, function(res){
          _this.fields = res.map(item => {
              item.index = parseInt(item.name.substr(1))
          })
          //_this.fields =  res.sort((a,b)=>(a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
          _this.fields =  res.sort((a,b)=>(a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
          const groupsSet = new Set()
          _this.fieldGroups = []
          for (let f of _this.fields){
            for (let g of f.groups){
              g.color = _this.colors[g.id % _this.colors.length]
              if ( !groupsSet.has(g.id) ){
                groupsSet.add(g.id)
                _this.fieldGroups.push(g)
              }
            }
          }
          _this.fieldGroups.sort((a,b)=> {return a.id < b.id})
        })
      },

    }
  }
</script>
